<template>
  <div class="bg-white">
    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      striped
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && items.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectAll: false,
      filterOptions: ["Select Branch", "Select Commissions"],
      selected: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: "check",
          // sortable: true,
          label: "",
        },
        {
          key: "user",
          // sortable: true,
          label: "USer",
          class: "w-25",
        },
        {
          key: "role",
          sortable: false,
          label: "Role",
          //   class: "w-50",
        },
        {
          key: "action",
          sortable: false,
          label: "Action",
          class: "w-50",
        },
        {
          key: "date",
          sortable: false,
          label: "Date",
        },
      ],
      items: [
        {
          // isActive: true,
          user: "Smith Jackson",
          role: "Super Admin",
          action:
            "Verified client Tunji Ademuyiwa's payment of N541,667.00 for Initial payment for 1 Starter plot at SATELITE TOWN (12 Months payment plan)",
          date: "18/04/22",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border-left: 1px solid var(--primary);
}

.t-cards {
  background: #fff;
  padding: 7px;
  box-shadow: -1px 1px 4px rgb(230, 230, 230);
}
</style>
