<template>
  <div>
    <h5>Create Admin</h5>
    <b-row class="mt-3">
      <b-col cols="12" md="12">
        <rm-input
          outlined
          type="text"
          v-model="form.first_name"
          label="First Name"
        >
        </rm-input>
      </b-col>
      <b-col cols="12" md="12">
        <rm-input
          outlined
          type="text"
          v-model="form.middle_name"
          label="Middle Name"
        />
      </b-col>
      <b-col cols="12">
        <rm-input
          outlined
          type="text"
          v-model="form.last_name"
          label="Last Name"
        >
        </rm-input>
      </b-col>
      <b-col cols="12">
        <div class="mb-4">
          <p class="mb-2">
            <small><b>Phone number</b></small>
          </p>
          <VuePhoneNumberInput
            v-model="form.phone_number"
            @update="resultsNumber = $event"
            default-country-code="NG"
          />
        </div>
      </b-col>
      <b-col cols="12">
        <rm-input outlined type="email" v-model="form.email" label="Email" />
      </b-col>

      <b-col cols="12">
        <rm-input outlined type="text" v-model="form.branch" label="Branch" />
      </b-col>
    </b-row>
    <b-row align-h="end" class="mt-lg-5 mt-0 pt-lg-5 pt-0">
      <b-col cols="2" class="mb-4">
        <rm-base-button
          @click="close"
          v-if="!isLoading"
          class="ml-auto"
          text="Cancel"
          bgColor="grey"
          textColor="black"
        ></rm-base-button>
      </b-col>
      <b-col cols="2" class="mb-4">
        <rm-base-button
          :loadingButton="isLoading"
          @click="createAdmin"
          class="ml-auto"
          text="Create"
          bgColor="primary"
          textColor="white"
        ></rm-base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import RmTextArea from "../../../components/global/RmTextArea.vue";
import countries from "@/utils/countries.json";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: { VuePhoneNumberInput },
  data() {
    return {
      isLoading: false,
      countries,
      gender: ["male", "female"],
      form: {
        title: "",
        password: "password",
      },
    };
  },
  computed: {
    states() {
      return countries.find((country) => country.name == this.form.country)
        ?.states;
    },
  },
  methods: {
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    async createAdmin() {
      try {
        this.isLoading = true;
        this.form.phone_number = this.resultsNumber.formattedNumber;
        await this.$store.dispatch("admin/createAdmin", this.form);

        this.$bvModal.hide("modal-lg-create-user");
      } catch (err) {
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
