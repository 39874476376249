<template>
  <div class="bg-white overflow-auto rounded-lg p-4 mt-4">
    <b-tabs pills content-class="mt-3" align="left">
      <b-tab exact-active-class="active" title="System Settings">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            text="New Usser"
            bgColor="tertiary--light"
            textColor="tertiary"
            v-b-modal.modal-lg-create-user
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <users-table></users-table>
      </b-tab>
      <b-tab exact-active-class="active" title="Roles">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>

          <rm-base-button
            text="New Usser"
            bgColor="tertiary--light"
            textColor="tertiary"
            ><rm-base-icon class="mr-2" name="plus-icon"
          /></rm-base-button>
        </div>
        <roles-table />
      </b-tab>

      <b-tab exact-active-class="active" title="Audit Logs">
        <!-- <Table :table-data="allClients" /> -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <p class="text-primary mb-0">1/5 pages</p>
        </div>
        <audit-logs />
      </b-tab>
    </b-tabs>

    <rm-base-modal id="create-user" size="lg">
      <create-admin />
    </rm-base-modal>
  </div>
</template>

<script>
import CreateAdmin from "./components/CreateAdmin";
import RolesTable from "./components/Roles";
import usersTable from "./components/Users";
import AuditLogs from "./components/AuditLogs";
export default {
  components: {
    usersTable,
    RolesTable,
    AuditLogs,
    CreateAdmin,
  },
};
</script>

<style lang="scss">
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  opacity: 1;
  font-size: 12px !important;
  color: var(--primary) !important;
  background-color: #fff;
  border-bottom: 2px solid var(--primary);
  border-radius: 0 !important;
}

.nav-pills .nav-link {
  font-weight: 900;
  color: var(--dark) !important;
  font-size: 12px !important;
}
</style>
